import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import FooterPioneer from "../components/Footer-Pioneer"
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
} from '@chakra-ui/react'
import { H1, H2, H4 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'
// Mapbox
import mapboxgl from '!mapbox-gl'
import ReactDOM from 'react-dom'
import 'mapbox-gl/dist/mapbox-gl.css'


const SMSConsentPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  
  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title="SMS Consent" description="Twillio SMS Consent" />
      <Box>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section}>
          <H1 variant="pageHeader" mb="5px">Internal Messaging Consent Record (Verbal Agreement)</H1>
          <p><i>Alopex Interaction Design</i></p>
          <p>This record documents verbal consent provided by the individual listed below to receive internal messaging from Alopex Interaction Design, specifically related to website outages and service disruptions. These messages are used solely for operational and internal communication with development staff and stakeholders.</p>
          <p><strong>Method of Consent</strong>: Verbal agreement (via phone / in-person / video call)</p>
          <H2>Purpose of Messaging</H2>
          <p>To notify internal team members of real-time website outages, performance issues, or urgent service-related incidents requiring immediate developer awareness or intervention.</p>
          <H2>Opt-In Summary</H2>
          <p>The individual verbally agreed to receive internal notifications for system outages. The scope of messaging was explained, including frequency, purpose, and the ability to opt out at any time.</p>
          <H2>Use Case Note:</H2>
          <p>These messages are not used for marketing or customer outreach, and are intended solely for the internal operations of Alopex Interaction Design.</p>
          <p>
            <strong>Recorded By</strong>: Steven Loyer<br/>
            <strong>Position</strong>: Director of development<br/>
            <strong>Date Recorded</strong>: 4/4/2025
          </p>

        </Container>
      </Box>
      <FooterPioneer fTitle="Let Alopex take you further." weather={data.openWeather} />
    </Layout>
  )
}

export const query = graphql`
  query aloSMSCon {
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`

export default SMSConsentPage
